@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   @font-face {
    font-family: 'Lusitana Regular';
    src: local('Lusitana-Regular'), url('./fonts/Lusitana-Regular.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Lusitana Bold';
    src: local('Lusitana-Bold'), url('./fonts/Lusitana-Bold.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Raleway Bold';
    src: local('Raleway-Bold'), url('./fonts/Raleway-Bold.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Raleway Regular';
    src: local('Raleway-Regular'), url('./fonts/Raleway-Regular.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Helvetica Bold';
    src: local('Helvetica Neu Bold'), url('./fonts/Helvetica Neu Bold.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Helvetica Regular';
    src: local('HelveticaNeueMed'), url('./fonts/HelveticaNeueMed.ttf') format('truetype');
  }
   @font-face {
    font-family: 'EBGaramond Regular';
    src: local('EBGaramond-Regular'), url('./fonts/EBGaramond-Regular.ttf') format('truetype');
  }
   @font-face {
    font-family: 'EBGaramond Bold';
    src: local('EBGaramond-Bold'), url('./fonts/EBGaramond-Bold.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Sherlock Press';
    src: local('Sherlock Press Personal Use'), url('./fonts/Sherlock Press Personal Use.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Noto Serif Bold';
    src: local('NotoSerif-Bold'), url('./fonts/NotoSerif-Bold.ttf') format('truetype');
  }
   @font-face {
    font-family: 'Classy Coiffeur Bold';
    src: local('Classy Coiffeur Bold'), url('./fonts/Classy Coiffeur Bold.otf') format('opentype');
  }
   @font-face {
    font-family: 'Gandhi Serif Bold';
    src: local('GandhiSerif-Bold'), url('./fonts/GandhiSerif-Bold.otf') format('opentype');
  }
   @font-face {
    font-family: 'Butler Bold';
    src: local('Butler_Bold'), url('./fonts/Butler_Bold.otf') format('opentype');
  }
   @font-face {
    font-family: 'Libre Caslon Bold';
    src: local('LibreCaslonText-Bold'), url('./fonts/LibreCaslonText-Bold.ttf') format('truetype');
  }
html {
    font-family: 'EBGaramond Regular';
    background-color: rgb(250 250 249);
  }
  .font-bold {
    font-family: 'Libre Caslon Bold';
  }

  .font-bold-inline {
    font-family: 'EBGaramond Bold';
  }

  .home-and-header {
    font-family: 'Libre Caslon Bold';
  }

  .header{
    text-transform: uppercase;
  }
  
  .brand {
    font-family: 'Butler Bold';
  }

  input[type='textarea']::placeholder{
    vertical-align:top;
  }

  button {
    font-family: 'Classy Coiffeur Bold' ;
  }
}